// Этот модуль работает с экспортом данных из сервиса генерации репортов v3
import router from '@/router'
export default {
    namespaced: true,
    state: {
      reportsList: [],
      reportsListUpdating: false,
      templateTransactions: '/reports/gateway/transactions/Registry',
      templateReceipts: '/reports/receipts/receipts',
      templateLoans: '/reports/hl/Loans',
      templatePartnerRewards: '/reports/partner_rewards/partner_rewards',
      urls: {
        reportGeneration: 'report-generator-nt/v1/proxy/gateway/v3/transactions',
        reportGenerationReceipts: 'report-generator-nt/v1/proxy/receipts/public/receipts',
        reportGenerationLoans: 'report-generator-nt/v1/proxy/loans/api/v1/arm/core/applications/',
        reportGenerationPartnerRewards: 'report-generator-nt/v1/proxy/partner_rewards/partner_rewards',
        reportsList: 'report-generator-nt/v1/report', // report?limit_to=10&sort_by=created_at
        reportStatus: 'report-generator-nt/v1/report', // report/a9049804-5411-4948-ad4a-bda1b4d5e96b
        reportDownload: 'report-generator-nt/v1/report' // report/88dfefe8-3c2b-4180-8202-35db3f977b52/download
      }
    },
    mutations: {
      updateReportsList (state, value) {
        state.reportsList = value
      },
      toggleReportsListUpdating (state, value) {
        state.reportsListUpdating = value
      },
    },
    actions: {
      refreshTableData (context, queryObject) {
        context.commit('pager/updateApiResponseReceived', false, { root: true })
        context.commit('pager/updateApiDataEmpty', true, { root: true })
        let url = context.state.urls.reportsList
        // Если к нам приходит не курсор, то мы обрабатываем queryObject.params дополнительно
        if (queryObject.type !== 'cursor'){
          queryObject.params.sort_by = 'created_at'
        }
        this.$axios.get(url, {
          params: queryObject.params
        })
          .then(response => {
            // Обрезаем все лишнее что пришло в ответе
            context.commit('pager/updateCursor', response.data.cursor, { root: true })
        context.commit('pager/updateCurrentOffset', queryObject.currentOffset, { root: true })
        context.commit('pager/updateApiResponseReceived', true, { root: true })
            if (response.data.reports.length > 0){
              context.commit('updateReportsList', response.data.reports)
              context.commit('pager/updateApiDataEmpty', false, { root: true })
            }else{
              context.commit('updateReportsList', [])
              context.commit('pager/updateApiDataEmpty', true, { root: true })
            }
          })
          .catch(error => {
            console.log('error in reports refreshTableData')
            console.log('error in promise', error)
          })
      },
      downloadReport (context, reportId){
        let url = context.state.urls.reportDownload + '/' + reportId + '/download'
        this.$axios.get(url, { responseType: 'blob' })
          .then(response => {
            var a = window.document.createElement("a");

            let blob = new Blob([response.data])
            a.href = window.URL.createObjectURL(blob)

            a.download = 'export_'+reportId+'.xlsx';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            /* csv
            var a = window.document.createElement("a");

            let blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' })
            a.href = window.URL.createObjectURL(blob, {
              type: "text/csv"
            })

            a.download = 'export_'+reportId+'.csv';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            */
            /* pdf
            var a = window.document.createElement("a");

            let blob = new Blob([response.data])
            a.href = window.URL.createObjectURL(blob, {
              type: "application/pdf"
            })

            a.download = 'export_'+reportId+'.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            */
          })
          .catch(error => {
            console.log('error in promise', error)
            alert('К сожалению, мы не смогли получить экспорт данных в формате Excel. Пожалуйста, обратитесь в техподдержку.')
          })
      },
      generateReport(context){
        // В данном месте мы определяем, какой шаблон нам нужен. Шаблоны мы записываем в переменных в этом самом модуле.
        // Когда их будет много, будет, возможно, смысл переделать на массив, но пока и так будет норм.
        // Определяем мы на какой странице находимся по activeTableNamespace из PagerStd.
        let templateCurrentVar = ''
        let urlCurrentVar = ''

        switch (context.rootState.pagerStd.activeTableNamespace) {
          case 'receipts':
            templateCurrentVar = 'templateReceipts'
            urlCurrentVar = 'reportGenerationReceipts'
            break
          case 'partners':
            templateCurrentVar = 'templatePartnerRewards'
            urlCurrentVar = 'reportGenerationPartnerRewards'
            break
          case 'lifeBroker':
            templateCurrentVar = 'templateLoans'
            urlCurrentVar = 'reportGenerationLoans'
            break
          default:
            templateCurrentVar = 'templateTransactions'
            urlCurrentVar = 'reportGeneration'
        }

        let url = context.state.urls[urlCurrentVar] + '?report_format=xlsx&template='+encodeURIComponent(context.state[templateCurrentVar])

        // Для экспорта мы всегда игнорируем курсор и лимиты. По крайней мере по умолчанию.
        // Запрашиваем фильтры.

        // Поскольку фильтры у нас работают в даже разных модулях в ЛайфМерчанте и вне его, делаем два обращения
        // а различать их мы можем по пути только на данный момент.
        let filter_by = ''
        if (router.history.current.path.includes('life_merchant')){
          context.dispatch('filters/prepareFilterByParameterAction', null, { root: true })
          filter_by = context.rootState.filters.filtersForApi.filterByString
          // this.$store.commit('pager/updateActiveTableNamespace', 'lifeMerchant', { root: true })
        }else{
          context.dispatch('filtersStd/prepareFilterByParameter', null, {root:true})
          filter_by = context.rootState.filtersStd.filterByString
        }

        url += '&filter_by=' + filter_by
        this.$axios.get(url)
          .then(response => {
            if (response.status === 200){
              this._vm.$toast.success('Запрос на экспорт данных был отправлен; получить файл можно на странице "Экспорт"');
            }
          })
          .catch(error => {
            context.commit('toggleErrorResponse', true)
            console.log('error in promise', error)
          })
      }
    }
  }
