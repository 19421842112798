import { render, staticRenderFns } from "./tableReceipts.vue?vue&type=template&id=366c341e&scoped=true"
import script from "./tableReceipts.vue?vue&type=script&lang=js"
export * from "./tableReceipts.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "366c341e",
  null
  
)

export default component.exports